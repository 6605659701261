import { CouriersAction, CouriersState, UPDATE_COURIERS } from "../types/couriers";

const INITIAL_STATE: CouriersState = {
    couriers: []
};

export const CouriersReducer = (state = INITIAL_STATE, action: CouriersAction): CouriersState => {
    switch (action.type) {
        case UPDATE_COURIERS:
            return {
                couriers: action.payload
            }
        default:
            return state;
    }
};
