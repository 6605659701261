import { RouteComponentProps } from "react-router-dom";
import { Driver, IGetDrivers, AddDriver as AddDriverApi, DeleteDriver as DeleteDriverApi } from "../../client/core";
import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Alert, Card, CardGroup, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { Color, Path, Storage } from "../../env";
import { Button, icon, variant } from "../form/Button";
import AddDriver from "./AddDriver";
import DeleteDriver from "./DeleteDriver";
import { FaCog } from "react-icons/fa";

export enum Activity {
    None,
    AddDriver,
    DeleteDriver,
}

export interface DriversState {
    activity: Activity;
    drivers: Driver[];
    activeDriver?: Driver;
    error: string;
}

export interface DriversProps extends RouteComponentProps {
    auth: typeof Auth;
    getDriversAPI: IGetDrivers;
}

export default class Drivers extends Component<DriversProps, DriversState> {
    constructor(props: DriversProps) {
        super(props);
        this.state = {
            activity: Activity.None,
            error: "",
            drivers: [],
        };
    }

    componentDidMount() {
        const courierID = localStorage.getItem(Storage.CourierID)
        if (!courierID) {
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.getDriversAPI.GetDrivers({
                courierID: courierID,
                identityToken: session.getIdToken().getJwtToken(),
            }).then(response => {
                if (response instanceof Error) {
                    return this.setState({
                        error: "We were unable to retrieve your drivers. If this problem persists please contract support@stumbled.online"
                    })
                }

                if (response.statusCode === 403) {
                    return this.signOut()
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "We were unable to retrieve your drivers. If this problem persists please contract support@stumbled.online"
                    })
                }

                this.setState({
                    drivers: response.body?.drivers || []
                })
            })
        }).catch(err => {
            return this.signOut()
        })
    }

    signOut = () => {
        this.props.auth.signOut()
        localStorage.clear()
        this.props.history.push(Path.Login)
    }

    handleActivityChange = (activity: Activity) => {
        this.setState({
            activity: activity,
        })
    }

    handleAddDriver = (driver: Driver) => {
        const { drivers } = this.state
        drivers.push(driver)
        this.setState({
            activity: Activity.None,
            drivers: drivers,
        })
    }

    handleActiveDriver = (driver: Driver, activity: Activity) => {
        this.setState({
            activeDriver: driver,
            activity: activity
        })
    }

    handleDeleteDriver = (driver: Driver) => {
        const { drivers } = this.state
        this.setState({
            activity: Activity.None,
            drivers: drivers.filter(d => d.id !== driver.id),
        })
    }



    render() {
        const deliveryViewProps: DriversViewProps = {
            ...this.props,
            auth: this.props.auth,
            activity: this.state.activity,
            drivers: this.state.drivers,
            error: this.state.error,
            activeDriver: this.state.activeDriver,
            onActivityChange: this.handleActivityChange,
            onAddDriver: this.handleAddDriver,
            onDeleteDriver: this.handleDeleteDriver,
            onActiveDriver: this.handleActiveDriver,
        };
        return <DriversView {...deliveryViewProps} />;
    }
}

export interface DriversViewProps extends RouteComponentProps {
    auth: typeof Auth;
    activity: Activity;
    activeDriver?: Driver;
    drivers: Driver[];
    error: string;
    onActivityChange: (activity: Activity) => void;
    onActiveDriver: (driver: Driver, activity: Activity) => void;
    onAddDriver: (driver: Driver) => void;
    onDeleteDriver: (driver: Driver) => void;
}

export const DriversView: FunctionComponent<DriversViewProps> = (props) => (
    <div>
        <Modal
            show={props.activity == Activity.AddDriver}
            onHide={() => props.onActivityChange(Activity.None)}
        >
            <Modal.Header>
                <Modal.Title>Add Driver</Modal.Title>
                <Button icon={icon.Close} onClick={() => props.onActivityChange(Activity.None)} variant={variant.Secondary} />
            </Modal.Header>
            <Modal.Body>
                <AddDriver {...props} addDriverAPI={new AddDriverApi()} addDriverCallback={driver => props.onAddDriver(driver)} imageStore={S3} />
            </Modal.Body>
        </Modal>

        <Modal
            show={props.activity == Activity.DeleteDriver}
            onHide={() => props.onActivityChange(Activity.None)}
        >
            <Modal.Header>
                <Modal.Title>Delete Driver</Modal.Title>
                <Button icon={icon.Close} onClick={() => props.onActivityChange(Activity.None)} variant={variant.Secondary} />
            </Modal.Header>
            <Modal.Body>
                <DeleteDriver {...props}
                    deleteDriverAPI={new DeleteDriverApi()}
                    cancelCallback={() => props.onActivityChange(Activity.None)}
                    deleteCallback={driver => props.onDeleteDriver(driver)}
                    driver={props.activeDriver!}
                />
            </Modal.Body>
        </Modal>

        <h1 style={{ marginBottom: "2rem" }}>Drivers</h1>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <DriversOptionBar addDriver={() => props.onActivityChange(Activity.AddDriver)} />
        <DriversGrid drivers={props.drivers} onDelete={driver => props.onActiveDriver(driver, Activity.DeleteDriver)} />
    </div>
);

export interface DriversOptionBarProps {
    addDriver: () => void;
}

export const DriversOptionBar: FunctionComponent<DriversOptionBarProps> = (props) => (
    <Row>
        <Col></Col>
        <Col style={{ textAlign: "right" }}>
            <Button icon={icon.Plus} onClick={() => props.addDriver()} variant={variant.Primary} />
        </Col>
    </Row>
)


export interface ListDriversProps {
    drivers: Driver[];
    onDelete: (driver: Driver) => void;
}

export const DriversGrid: FunctionComponent<ListDriversProps> = (props) => (
    <Row>
        <CardGroup>
            {props.drivers.map(driver => (
                <Col xs={12} sm={6} lg={3} style={{ padding: ".5rem" }}>
                    <Card>
                        {!driver.image ? (
                            <Card.Img
                                style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "cover",
                                }}
                                src={`${process.env.REACT_APP_DEFAULT_IMAGE}${driver.name.charAt(0).toUpperCase()}`}
                            />
                        ) : (
                            <Card.Img
                                style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "cover",
                                }}
                                src={driver.image}
                            />
                        )}
                        <Card.Body>
                            <Row>
                                <Col xs={8}>
                                    <Card.Title>{driver.name}</Card.Title>
                                </Col>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" style={{ background: Color.Primary }} id={`${driver.id}-actions`}>
                                            <FaCog />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => props.onDelete(driver)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </CardGroup>
    </Row>
);