import { FunctionComponent } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Color, Path } from "../../env";

export interface FooterProps { }

export const Footer: FunctionComponent<FooterProps> = (props) => (
    <div style={{ background: Color.White, fontWeight: 500, position: "fixed", bottom: 0, width: "100%", zIndex: 9 }}>
        <Container style={{ textAlign: "center", fontSize: ".8rem" }}>
            <Row style={{ padding: ".2rem" }}>
                <Col>
                    <div style={{ float: "left" }}>
                        <li style={{ display: "inline-block", marginRight: ".5rem", marginLeft: ".5rem" }}><a href={"https://stumbled.online/contact.html"} target={"_blank"} style={{ color: Color.Primary }}>Contact us</a></li>
                    </div>
                </Col>
                <Col>
                    <li style={{ display: "inline-block", marginRight: ".5rem", marginLeft: ".5rem", color: Color.Primary }}>© 2023, Stumbled</li>
                </Col>
                <Col>
                    <div style={{ float: "right" }}>
                        <li style={{ display: "inline-block", marginRight: ".5rem", marginLeft: ".5rem" }}><a href={"https://stumbled.online/terms.html"} target={"_blank"} style={{ color: Color.Primary }}>Terms and Conditions</a></li>
                        <li style={{ display: "inline-block", marginRight: ".5rem", marginLeft: ".5rem" }}><a href={"https://stumbled.online/privacy.html"} target={"_blank"} style={{ color: Color.Primary }}>Privacy</a></li>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);