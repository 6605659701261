import React, { FunctionComponent, ReactNode } from "react";
import {
    Route,
    BrowserRouter as Router,
    Switch,
    RouteComponentProps,
} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Credentials from "../components/register/person/Credentials";
import { MemberRegisterConfirmation } from "../components/register/person/MemberRegisterConfirmation";
import { Color, Path } from "../env";
import {
    RegisterCourier as RegisterCourierAPI,
    PutCourierName,
    SetCourierAddress as SetCourierAddressAPI,
    GetDelivery,
    GetCourierSummary,
    RegisterAccount as RegisterAccountAPI,
    SetCourierContactDetails as SetCourierContactDetailsAPI,
    GetAttachedCouriers,
    GetDeliveries,
    UpdateDeliveryStatus,
    GetDrivers,
    AssignDriverToDelivery,
} from "../client/core";
import Login from "../components/register/person/Login";
import Navbar, { Sidebar } from "../components/menu/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import CourierDashboard from "../components/dashboard/CourierDashboard";
import Delivery from "../components/delivery/Delivery";
import { Footer } from "../components/menu/Footer";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import ResetPassword from "../components/register/person/ResetPassword";
import RegisterCourier from "../components/register/courier/RegisterCourier";
import RegisterAccount from "../components/register/courier/RegisterAccount";
import SetCourierAddress from "../components/register/courier/SetCourierAddress";
import CourierName from "../components/register/courier/CourierName";
import SetContactDetails from "../components/register/courier/SetContactDetails";
import ListDeliveries from "../components/deliveries/ListDeliveries";
import Drivers from "../components/drivers/Drivers";
import NewMember from "../components/register/person/NewMember";


export const Routes: FunctionComponent<{}> = () => {
    const dispatch = useDispatch()
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={Path.Home}
                    render={(props) =>
                        Screen(
                            <CourierDashboard
                                {...props}
                                auth={Auth}
                                getCourierAPI={new GetCourierSummary()}
                                registerCourierAPI={new RegisterAccountAPI()}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.CourierDashboard}
                    render={(props) =>
                        Screen(
                            <CourierDashboard
                                {...props}
                                auth={Auth}
                                getCourierAPI={new GetCourierSummary()}
                                registerCourierAPI={new RegisterAccountAPI()}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterMember}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <Credentials {...props} auth={Auth} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.RegisterMemberConfirmation}
                    render={(props) =>
                        <MemberRegisterConfirmation />
                    }
                />
                <Route
                    exact
                    path={Path.RegisterCourier}
                    render={(props) =>
                        Screen(
                            <RegisterCourier
                                auth={Auth}
                                coreAPI={new RegisterCourierAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Drivers}
                    render={(props) =>
                        Screen(
                            <Drivers
                                auth={Auth}
                                getDriversAPI={new GetDrivers()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterCourierName}
                    render={(props) =>
                        Screen(
                            <CourierName
                                auth={Auth}
                                coreAPI={new PutCourierName()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterCourierContact}
                    render={(props) =>
                        Screen(
                            <SetContactDetails
                                auth={Auth}
                                coreAPI={new SetCourierContactDetailsAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterCourierAccount}
                    render={(props) =>
                        Screen(
                            <RegisterAccount
                                auth={Auth}
                                registerAccountAPI={new RegisterAccountAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.RegisterCourierAddress}
                    render={(props) =>
                        Screen(
                            <SetCourierAddress
                                auth={Auth}
                                coreAPI={new SetCourierAddressAPI()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Delivery}
                    render={(props) =>
                        Screen(
                            <Delivery
                                auth={Auth}
                                getDeliveryAPI={new GetDelivery()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Deliveries}
                    render={(props) =>
                        Screen(
                            <ListDeliveries
                                auth={Auth}
                                getDeliveriesAPI={new GetDeliveries()}
                                updateDeliveryStatusAPI={new UpdateDeliveryStatus()}
                                assignDriverToDeliveryAPI={new AssignDriverToDelivery()}
                                {...props}
                            />,
                            props,
                            dispatch
                        )
                    }
                />
                <Route
                    exact
                    path={Path.Login}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <Login auth={Auth} {...props} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.NewMember}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <NewMember auth={Auth} {...props} />
                        </div>
                    }
                />
                <Route
                    exact
                    path={Path.ResetPassword}
                    render={(props) =>
                        <div style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                            <ResetPassword auth={Auth} {...props} />
                        </div>
                    }
                />
            </Switch>
        </Router>
    );
}

function Screen(component: ReactNode, props: RouteComponentProps, dispatch: Dispatch) {
    return (
        <div>
            <Navbar couriersDispatch={dispatch} getAttachedCouriersAPI={new GetAttachedCouriers()} auth={Auth} {...props} />
            <Container fluid style={{ maxWidth: "100%", height: "100vh" }}>
                <Row style={{ height: "100vh" }}>
                    <Col md={3} lg={2} className={"d-none d-md-block"} style={{ background: Color.LightGrey, padding: "4rem 0 0 0" }}>
                        <Row style={{ position: "fixed", width: "100%", margin: 0, padding: 0 }}>
                            <Col md={3} lg={2} style={{ padding: 0 }} className={"d-none d-md-block"}>
                                <Sidebar {...props} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={9} lg={10} style={{ background: Color.White, paddingTop: "4rem", paddingLeft: "4vw", paddingRight: "4vw", marginTop: "2rem", zIndex: 2 }}>
                        {component}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}