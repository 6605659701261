import { boolean, string } from "@pact-foundation/pact/src/dsl/matchers";
import axios, { AxiosRequestConfig } from "axios";

export interface PutCourierNameInput {
    identityToken: string;
    courierID: string;
    courierName: string;
}

export interface PutCourierNameOutput {
    statusCode: number;
}

export interface IPutCourierName {
    PutCourierName(input: PutCourierNameInput): Promise<PutCourierNameOutput>;
}

export class PutCourierName implements IPutCourierName {
    async PutCourierName(input: PutCourierNameInput): Promise<PutCourierNameOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.courierID}/name`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                name: input.courierName,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface RegisterCourierInput {
    identityToken: string;
}

export interface RegisterCourierOutput {
    statusCode: number;
    courierID: string;
}

export interface IRegisterCourier {
    RegisterCourier(input: RegisterCourierInput): Promise<RegisterCourierOutput>;
}

export class RegisterCourier implements IRegisterCourier {
    async RegisterCourier(input: RegisterCourierInput): Promise<RegisterCourierOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/shop",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                entity: "courier"
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                courierID: response.data["id"],
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                    courierID: "",
                };
            }
            throw err
        }
    }
}

export interface AddressDetails {
    id: string;
    address: string;
}

export interface SetCourierAddressInput {
    identityToken: string;
    courierID: string;
    addressDetails: AddressDetails;
}

export interface SetCourierAddressOutput {
    statusCode: number;
}

export interface ISetCourierAddress {
    SetCourierAddress(input: SetCourierAddressInput): Promise<SetCourierAddressOutput>;
}

export class SetCourierAddress implements ISetCourierAddress {
    async SetCourierAddress(
        input: SetCourierAddressInput
    ): Promise<SetCourierAddressOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.courierID +
                "/address",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                id: input.addressDetails.id,
                address: input.addressDetails.address,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface ContactDetails {
    email: string
    phone: string
    whatsapp?: string
}

export interface SetCourierContactDetailsInput {
    identityToken: string;
    courierID: string;
    contactDetails: ContactDetails;
}

export interface SetCourierContactDetailsOutput {
    statusCode: number;
}

export interface ISetCourierContactDetails {
    SetCourierContactDetails(input: SetCourierContactDetailsInput): Promise<SetCourierContactDetailsOutput>;
}

export class SetCourierContactDetails implements ISetCourierContactDetails {
    async SetCourierContactDetails(
        input: SetCourierContactDetailsInput
    ): Promise<SetCourierContactDetailsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.courierID +
                "/contact",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                email: input.contactDetails.email,
                phone: input.contactDetails.phone,
                whatsapp: input.contactDetails.whatsapp,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetCourierSummaryInput {
    identityToken: string;
    courierID: string;
}

export interface GetCourierSummaryOutput {
    statusCode: number;
    courierSummary?: CourierSummary;
}

export interface CourierSummary {
    name: string;
    address: string;
    accountStatus: string;
    deliveryStatus: string;
    logo: string;
    email: string;
    phone: string;
    whatsapp?: string;
}

export interface IGetCourierSummary {
    GetCourierSummary(input: GetCourierSummaryInput): Promise<GetCourierSummaryOutput>;
}

export interface GetCourierIDInput {
    identityToken: string;
}

export interface GetCourierIDOutput {
    statusCode: number;
    courierID?: string;
}

export interface IGetCourierID {
    GetCourierID(input: GetCourierIDInput): Promise<GetCourierIDOutput>;
}

export interface IGetCourier extends IGetCourierID, IGetCourierSummary { }

export class GetCourierSummary implements IGetCourierID, IGetCourierSummary {
    async GetCourierSummary(
        input: GetCourierSummaryInput
    ): Promise<GetCourierSummaryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.courierID}`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                courierSummary: {
                    name: response.data.name,
                    address: response.data.address,
                    accountStatus: response.data.accountStatus,
                    deliveryStatus: response.data.deliveryStatus,
                    logo: response.data.logo,
                    email: response.data.email,
                    phone: response.data.phone,
                    whatsapp: response.data.whatsapp,
                },
            };
        } catch (err: any) {
            throw err
        }
    }

    async GetCourierID(input: GetCourierIDInput): Promise<GetCourierIDOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/me/shop",
            params: {
                "entity": "courier"
            },
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);

            return {
                statusCode: response.status,
                courierID: response.data.id,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface CourierAbridged {
    id: string;
    name: string;
    address: string;
    logo: string;
}

export interface GetAttachedCouriersInput {
    identityToken: string;
}

export interface GetAttachedCouriersOutput {
    statusCode: number;
    couriers?: CourierAbridged[];
}

export interface IGetAttachedCouriers {
    GetAttachedCouriers(input: GetAttachedCouriersInput): Promise<GetAttachedCouriersOutput>;
}

export class GetAttachedCouriers implements IGetAttachedCouriers {
    async GetAttachedCouriers(
        input: GetAttachedCouriersInput
    ): Promise<GetAttachedCouriersOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me/shops",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            params: {
                "entity": "courier"
            }
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                couriers: response.data.shops
            };
        } catch (err: any) {
            throw err
        }
    }
}

export interface UpdateDeliveryRadiusInput {
    identityToken: string;
    courierID: string;
    radius: number;
}

export interface UpdateDeliveryRadiusOutput {
    statusCode: number;
}

export interface IUpdateDeliveryRadius {
    UpdateDeliveryRadius(
        input: UpdateDeliveryRadiusInput
    ): Promise<UpdateDeliveryRadiusOutput>;
}

export class UpdateDeliveryRadius implements IUpdateDeliveryRadius {
    async UpdateDeliveryRadius(
        input: UpdateDeliveryRadiusInput
    ): Promise<UpdateDeliveryRadiusOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.courierID +
                "/delivery/radius",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                radius: input.radius,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateDeliveryDateInput {
    identityToken: string;
    courierID: string;
    dates: DeliveryDate[];
}

export interface DeliveryDate {
    day: Day;
    timeranges: Timerange[];
    orderBefore?: OrderDate;
}

export interface OrderDate {
    day: Day;
    time: string;
}

export interface Timerange {
    start: string;
    end: string;
}

export interface UpdateDeliveryDateOutput {
    statusCode: number;
}

export type Day =
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";

export interface IUpdateDeliveryDate {
    UpdateDeliveryDate(
        input: UpdateDeliveryDateInput
    ): Promise<UpdateDeliveryDateOutput | Error>;
}

export class UpdateDeliveryDate implements IUpdateDeliveryDate {
    async UpdateDeliveryDate(
        input: UpdateDeliveryDateInput
    ): Promise<UpdateDeliveryDateOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.courierID +
                "/delivery/date",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                dates: input.dates,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateDeliveryPriceInput {
    identityToken: string;
    courierID: string;
    price: number;
}

export interface UpdateDeliveryPriceOutput {
    statusCode: number;
}

export interface IUpdateDeliveryPrice {
    UpdateDeliveryPrice(
        input: UpdateDeliveryPriceInput
    ): Promise<UpdateDeliveryPriceOutput | Error>;
}

export class UpdateDeliveryPrice implements IUpdateDeliveryPrice {
    async UpdateDeliveryPrice(
        input: UpdateDeliveryPriceInput
    ): Promise<UpdateDeliveryPriceOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.courierID +
                "/delivery/price",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                price: input.price,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetDeliveryInput {
    identityToken: string;
    courierID: string;
}

export interface GetDeliveryOutput {
    statusCode: number;
    delivery?: DeliverySettings;
}

export interface DeliverySettings {
    radius: number;
    price: number;
    dates: DeliveryDate[];
}

export interface IGetDelivery {
    GetDelivery(input: GetDeliveryInput): Promise<GetDeliveryOutput>;
}

export class GetDelivery implements IGetDelivery {
    async GetDelivery(input: GetDeliveryInput): Promise<GetDeliveryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.courierID +
                "/delivery",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                delivery: {
                    radius: response.data.radius,
                    price: response.data.price,
                    dates: response.data.dates,
                },
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export enum OrderType {
    OrderTypeDelivery = "delivery"
}

export interface OrderItem {
    id: string;
    name: string;
    price: number;
    uom?: string;
    volume?: number;
    variants?: OrderItemVariant[];
}

export interface OrderItemVariant {
    name: string
    value: string
}

export interface OrderDeliveryDate {
    day: Day;
    time: Timerange;
}

export interface RegisterAccountInput {
    identityToken: string;
    refreshURL: string;
    returnURL: string;
    courierID: string
}

export interface RegisterAccountOutput {
    statusCode: number;
    accountLink?: string;
}

export interface IRegisterAccount {
    RegisterAccount(input: RegisterAccountInput): Promise<RegisterAccountOutput>;
}

export class RegisterAccount implements IRegisterAccount {
    async RegisterAccount(input: RegisterAccountInput): Promise<RegisterAccountOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/shop/" + input.courierID + "/account",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                refreshURL: input.refreshURL,
                returnURL: input.returnURL,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                accountLink: response.data["accountLink"],
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                    accountLink: "",
                };
            }
            throw err
        }
    }
}

export interface IGetDeliveries {
    GetDeliveries(input: GetDeliveriesInput): Promise<GetDeliveriesOutput | Error>;
}

export interface GetDeliveriesInput {
    identityToken: string;
    courierID: string;
}

export interface GetDeliveriesOutput {
    statusCode: number;
    response?: GetDeliveriesResponse;
}

export interface GetDeliveriesResponse {
    deliveryDays: string[];
    shopPickups: Record<string, Pickup[]>;
    deliveries: Record<string, Record<string, Delivery[]>>;
    drivers: Driver[];
}

export interface Delivery {
    paymentId: string;
    address: string;
    customerEmail: string;
    status: string;
    price: number;
    date: OrderDeliveryDate;
    details: string;
    orders: Record<string, Order>
    driver?: Driver;
}

export interface Pickup {
    shop: ShopSummary;
    packages: Package[];
}

export interface Package {
    address: string;
    itemTotal: number;
}

export interface Order {
    shop: ShopSummary;
    itemTotal: number;
}

export interface ShopSummary {
    id: string;
    name: string;
    address: string;
}

export interface AddressMetadata {
    id: string
    lat: number
    lng: number
}

export class GetDeliveries implements IGetDeliveries {
    async GetDeliveries(input: GetDeliveriesInput): Promise<GetDeliveriesOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/couriers/" +
                input.courierID +
                "/deliveries",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                response: response.data
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IUpdateDeliveryStatus {
    UpdateDeliveryStatus(input: UpdateDeliveryStatusInput): Promise<UpdateDeliveryStatusOutput | Error>;
}

export interface UpdateDeliveryStatusInput {
    identityToken: string;
    courierID: string;
    paymentID: string;
    status: string;
}

export interface UpdateDeliveryStatusOutput {
    statusCode: number;
}

export class UpdateDeliveryStatus implements IUpdateDeliveryStatus {
    async UpdateDeliveryStatus(input: UpdateDeliveryStatusInput): Promise<UpdateDeliveryStatusOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                `${process.env.REACT_APP_CORE_API_HOSTNAME}/couriers/${input.courierID}/deliveries/${input.paymentID}/status`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                status: input.status,
            }
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface Driver {
    id?: string;
    name: string;
    email?: string;
    image?: string;
}

export interface IAddDriver {
    AddDriver(input: AddDriverInput): Promise<AddDriverOutput | Error>;
}

export interface AddDriverInput {
    identityToken: string;
    courierID: string;
    driver: Driver;
}

export interface AddDriverOutput {
    statusCode: number;
    body?: AddDriverResponseBody;
}

export interface AddDriverResponseBody {
    driver: Driver;
}

export class AddDriver implements IAddDriver {
    async AddDriver(input: AddDriverInput): Promise<AddDriverOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url:
                `${process.env.REACT_APP_CORE_API_HOSTNAME}/couriers/${input.courierID}/drivers`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                name: input.driver.name,
                image: input.driver.image,
                email: input.driver.email
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: {
                    driver: response.data.driver,
                }
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IGetDrivers {
    GetDrivers(input: GetDriversInput): Promise<GetDriversOutput | Error>;
}

export interface GetDriversInput {
    identityToken: string;
    courierID: string;
}

export interface GetDriversOutput {
    statusCode: number;
    body?: GetDriversResponse
}

export interface GetDriversResponse {
    drivers: Driver[]
}

export class GetDrivers implements IGetDrivers {
    async GetDrivers(input: GetDriversInput): Promise<GetDriversOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                `${process.env.REACT_APP_CORE_API_HOSTNAME}/couriers/${input.courierID}/drivers`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: {
                    drivers: response.data.drivers
                }
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IDeleteDriver {
    DeleteDriver(input: DeleteDriverInput): Promise<DeleteDriverOutput | Error>;
}

export interface DeleteDriverInput {
    identityToken: string;
    courierID: string;
    driverID: string;
}

export interface DeleteDriverOutput {
    statusCode: number;
}

export class DeleteDriver implements IDeleteDriver {
    async DeleteDriver(input: DeleteDriverInput): Promise<DeleteDriverOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "DELETE",
            url:
                `${process.env.REACT_APP_CORE_API_HOSTNAME}/couriers/${input.courierID}/drivers/${input.driverID}`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface IAssignDriverToDelivery {
    AssignDriverToDelivery(input: AssignDriverToDeliveryInput): Promise<AssignDriverToDeliveryOutput | Error>;
}

export interface AssignDriverToDeliveryInput {
    identityToken: string;
    courierID: string;
    deliveryID: string;
    driver: Driver;
}

export interface AssignDriverToDeliveryOutput {
    statusCode: number;
}

export class AssignDriverToDelivery implements IAssignDriverToDelivery {
    async AssignDriverToDelivery(input: AssignDriverToDeliveryInput): Promise<AssignDriverToDeliveryOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                `${process.env.REACT_APP_CORE_API_HOSTNAME}/couriers/${input.courierID}/deliveries/${input.deliveryID}/driver`,
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                name: input.driver.name
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}