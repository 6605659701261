import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Form, Alert, Card, Col, Row } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { RouteComponentProps } from "react-router-dom";
import { IRegisterAccount } from "../../../client/core";
import { Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";

export interface RegisterAccountState {
    error: string;
}

export interface RegisterAccountProps extends RouteComponentProps {
    registerAccountAPI: IRegisterAccount;
    auth: typeof Auth;
}

export default class RegisterAccount extends Component<
    RegisterAccountProps,
    RegisterAccountState
> {
    constructor(props: RegisterAccountProps) {
        super(props);
        this.state = {
            error: "",
        };
    }

    handleStripeSubmit = () => {
        const CourierID = localStorage.getItem(Storage.CourierID)
        if (!CourierID) {
            localStorage.removeItem(Storage.IsLoggedIn)
            return this.props.auth.signOut()
        }
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.registerAccountAPI
                    .RegisterAccount({
                        identityToken: token,
                        courierID: CourierID!,
                        refreshURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.CourierDashboard,
                        returnURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.CourierDashboard,
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            localStorage.clear()
                            return this.props.history.push(Path.Login);
                        }

                        if (output.statusCode != 200) {
                            return this.setState({
                                error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                            });
                        }

                        return window.location.replace(output.accountLink!);
                    })
                    .catch(() =>
                        this.setState({
                            error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                        })
                    );
            })
            .catch(() => {
                localStorage.removeItem(Storage.IsLoggedIn)
                this.props.history.push(Path.Login);
            });
    };

    render() {
        const registerProps: RegisterAccountFormProps = {
            error: this.state.error,
            onSubmit: this.handleStripeSubmit,
        };
        return <RegisterAccountForm {...registerProps} />;
    }
}

export interface RegisterAccountFormProps {
    error: string;
    onSubmit: () => void;
}

export const RegisterAccountForm: FunctionComponent<RegisterAccountFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={7}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <h4 style={{ textAlign: "center" }}>Registration Form</h4>
                            </Col>
                            <Col xs={12}>
                                <Card.Text>We have partnered with Stripe to enable secure online payments through the platform.</Card.Text>
                                <Card.Text>
                                    <p>You can register an account with Stripe by clicking the button below. Stripe will take you through their
                                        registration process. The details that you'll need are as follows:</p>
                                    <ul>
                                        <li>Courier Name</li>
                                        <li>Courier Address</li>
                                        <li>Courier Registration Number</li>
                                        <li>Courier Director (at least one)</li>
                                        <li>A Person with significant control</li>
                                        <li>Courier VAT Number (if applicable)</li>
                                        <li>Documents of personal identification</li>
                                        <li>Customer Support Details</li>
                                    </ul>
                                    <p>
                                        Please ensure that all details entered are correct to prevent Stripe declining your account. If you do not
                                        have all the information at moment you can add additional information later from the Stumbled dashboard.
                                    </p>
                                </Card.Text>
                            </Col>
                            <Col>
                                <Button variant={variant.Primary} name={"Register with Stripe Payments"} onClick={() => props.onSubmit()} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={5}>
                <Card>
                    <Card.Body>
                        <Card.Title><InfoCircle size={18} /> Info about Stripe</Card.Title>
                        <Card.Text>
                            <p>
                                Stripe is one of the leading online payment processors. Stripe offers numerous tools to manage payments such as invoicing, payment dashboard, etc.
                                It also plugs into accountancy software such as Xero. For more information about Stripe please visit their website <a target="_blank" href={"https://stripe.com/gb"}>here</a>.
                            </p>
                            <p>
                                Stripe does have a service charge per transaction of 1.5% + 20p (for UK cards).
                            </p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Form>
);
