import { Auth } from "aws-amplify";
import { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { AddressDetails, ISetCourierAddress } from "../../../client/core";
import { Path, Storage } from "../../../env";
import Autocomplete from "react-google-autocomplete";
import { InfoCircle } from "react-bootstrap-icons";
import { Button, variant } from "../../form/Button";

export interface SetCourierAddressState {
    search: string;
    error: string;
    addressDetails: AddressDetails;
}

export interface SetCourierAddressProps extends RouteComponentProps {
    coreAPI: ISetCourierAddress;
    auth: typeof Auth;
}

export default class CourierAddress extends Component<
    SetCourierAddressProps,
    SetCourierAddressState
> {
    constructor(props: SetCourierAddressProps) {
        super(props);
        this.state = {
            search: "",
            error: "",
            addressDetails: {
                id: "",
                address: "",
            },
        };
    }

    handleSearch = (place: google.maps.places.PlaceResult) => {
        const addressDetails: AddressDetails = {
            id: place.place_id!,
            address: place.formatted_address!,
        };
        this.setState({
            addressDetails: addressDetails,
        });
    };

    handleSubmit = () => {
        const CourierID = localStorage.getItem(Storage.CourierID);
        if (CourierID == null) {
            return this.setState({
                error: "Courier could not be found - please navigate back to the Courier dashboard to resync",
            });
        }
        if (this.state.addressDetails.id.trim() === "") {
            return this.setState({
                error: "Please enter the address of your Courier and select one of the recommendations",
            });
        }
        this.props.auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            this.props.coreAPI
                .SetCourierAddress({
                    identityToken: token,
                    courierID: CourierID,
                    addressDetails: {
                        id: this.state.addressDetails.id,
                        address: this.state.addressDetails.address,
                    },
                })
                .then((output) => {
                    if (output.statusCode === 403) {
                        localStorage.removeItem(Storage.CourierID);
                        return this.setState({
                            error: "You were forbidden to change the Courier address - please navigate back to the Courier dashboard.",
                        });
                    }
                    return this.props.history.push(Path.RegisterCourierAccount);
                })
                .catch((err) =>
                    this.setState({
                        error: err.message,
                    })
                );
        });
    };

    render() {
        const props: SetCourierAddressFormProps = {
            search: this.state.search,
            error: this.state.error,
            apiKey: process.env.REACT_APP_CORE_GOOGLE_PLACES_KEY!,
            onSubmit: this.handleSubmit,
            onChange: this.handleSearch,
        };
        return <SetCourierAddressForm {...props} />;
    }
}

export interface SetCourierAddressFormProps {
    search: string;
    error: string;
    apiKey: string;
    onSubmit: () => void;
    onChange: (place: google.maps.places.PlaceResult) => void;
}

export const SetCourierAddressForm: FunctionComponent<SetCourierAddressFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={7}>
                <Card>
                    <Card.Body>
                        <Form.Group style={{ "width": "100%" }}>
                            <h4 style={{ textAlign: "center" }}>Registration Form</h4>
                            <Row>
                                <Col sm={12}>
                                    <Form.Label>Courier Address</Form.Label>
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} sm={12}>
                                    <Autocomplete
                                        apiKey={props.apiKey}
                                        onPlaceSelected={(place) => props.onChange(place)}
                                        style={{
                                            width: "100%",
                                            padding: ".375rem .75rem",
                                            borderRadius: ".25rem",
                                            border: "1px solid #ced4da",
                                        }}
                                        options={{
                                            types: ["address"],
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginBottom: "1rem" }} sm={12}>
                                    <Button
                                        id="set-Courier-address-submit-btn"
                                        variant={variant.Primary}
                                        name={"Next"}
                                        onClick={() => props.onSubmit()}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={5}>
                <Card>
                    <Card.Body>
                        <Form.Group style={{ "width": "100%" }}>
                            <Card.Title><InfoCircle size={18} /> Info about address</Card.Title>
                            <Card.Text>
                                <p>This is the address that you will be making deliveries from. The delivery radius that you set in the platform will define your customer delivery catchment.</p>
                                <p>If a customer's delivery address is in your delivery catchment they will be able to make purchases from your Courier.
                                    If the customer's delivery address is NOT in your delivery catchment, your Courier will not be displayed and customers will not be able to make purchase from your Courier.</p>
                            </Card.Text>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Form>
);
