import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Container, Nav, Navbar as BNavbar, Dropdown, NavItem, NavLink, Row, Col } from "react-bootstrap";
import { FaBiking, FaBox, FaBoxes, FaCreditCard, FaHome, FaLocationArrow, FaPlug, FaPlus, FaStore, FaUser } from "react-icons/fa";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../env";
import { IGetAttachedCouriers, CourierAbridged } from "../../client/core";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { CouriersAction, UPDATE_COURIERS } from "../../state/types/couriers";
import { RootState } from "../../state/store";
import { MdAccountCircle, MdLogout, MdPeople } from "react-icons/md";

export interface NavbarState {
    isMobile: boolean;
    isLoggedIn: boolean;
    activeCourier: CourierAbridged;
}

export type CouriersDispatch = Dispatch<CouriersAction>;

export interface NavbarProps extends RouteComponentProps {
    auth: typeof Auth;
    getAttachedCouriersAPI: IGetAttachedCouriers;
    couriersDispatch: CouriersDispatch;
    couriers: CourierAbridged[];
}

export class Navbar extends Component<NavbarProps, NavbarState> {
    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            activeCourier: {
                name: "",
                address: "",
                id: "",
                logo: "",
            },
            isMobile: false,
            isLoggedIn: localStorage.getItem(Storage.IsLoggedIn) != null,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.getCouriers();
    }

    getCouriers = (): void => {
        if (!this.state.isLoggedIn) {
            return
        }
        this.props.auth.currentSession().then(session => {
            this.props.getAttachedCouriersAPI.GetAttachedCouriers({
                identityToken: session.getIdToken().getJwtToken(),
            }).then(response => {
                if (response.statusCode !== 200) {
                    return this.handleLogout()
                }

                if (!response.couriers || response.couriers.length < 1) {
                    return
                }

                this.props.couriersDispatch({
                    type: UPDATE_COURIERS,
                    payload: response.couriers
                })

                let courierID = localStorage.getItem(Storage.CourierID)
                const firstCourier: CourierAbridged = response.couriers[0]

                if (!courierID) {
                    localStorage.setItem(Storage.CourierID, firstCourier.id)
                    courierID = firstCourier.id
                }

                let activeCourier = response.couriers.find(courier => courier.id == courierID)
                if (!activeCourier) {
                    localStorage.setItem(Storage.CourierID, firstCourier.id)
                    activeCourier = firstCourier
                }

                this.setState({
                    activeCourier: activeCourier,
                })
            })
        }).catch(() => {
            this.handleLogout()
        })
    }

    handleAddCourier = () => {
        this.props.history.push(Path.RegisterCourier)
    }

    handleChangeCourier = (CourierID: string) => {
        localStorage.setItem(Storage.CourierID, CourierID)
        window.location.reload()
    }

    resize() {
        this.setState({
            isMobile: window.innerWidth <= 760
        });
    }

    componentDidUpdate() {
        const isLoggedIn = localStorage.getItem(Storage.IsLoggedIn) != null;
        if (this.state.isLoggedIn == isLoggedIn) {
            return;
        }
        this.setState({
            isLoggedIn: isLoggedIn,
        });
    }

    handleSelect = (path: Path) => {
        this.props.history.push(path);
    };

    handleLogout = () => {
        this.props.auth.signOut();
        localStorage.removeItem(Storage.IsLoggedIn);
        return this.props.history.push(Path.Login);
    };

    render() {
        const props: NavbarViewProps = {
            onSelect: this.handleSelect,
            onLogout: this.handleLogout,
            onAddCourier: this.handleAddCourier,
            onChangeCourier: this.handleChangeCourier,
            couriers: this.props.couriers,
            activeCourier: this.state.activeCourier,
        };
        if (this.state.isLoggedIn) {
            if (this.state.isMobile) {
                return <MobileCourierAdminNavbar {...props} />
            } else {
                return <DesktopCourierAdminNavbar {...props} />
            }
        } else {
            return <UnauthenticatedNavbar {...props} />
        }
    }
}

const mapState = (state: RootState) => ({
    couriers: state.Couriers.couriers || [],
});

const connector = connect(mapState, {});

export default connector(Navbar)

export interface SidebarState {
    isLoggedIn: boolean

}

export interface SidebarProps extends RouteComponentProps { }

export class Sidebar extends Component<SidebarProps, SidebarState> {
    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            isLoggedIn: localStorage.getItem(Storage.IsLoggedIn) != null,
        };
    }

    handleSelect = (path: Path) => {
        this.props.history.push(path);
    };

    render() {
        const props: SidebarViewProps = {
            onSelect: this.handleSelect,
        };
        if (!this.state.isLoggedIn) {
            return <></>
        }
        return <SidebarView {...props} />
    }
}

export interface SidebarViewProps {
    onSelect: (path: Path) => void;
}


export const SidebarView: FunctionComponent<SidebarViewProps> = (props) => (
    <BNavbar className="nav-pills flex-column" style={{ height: "100vh" }}>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Home)}>
            <FaHome size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Dashboard</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Drivers)}>
            <FaUser size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Drivers</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Delivery)}>
            <FaLocationArrow size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Delivery Settings</span>
        </Nav.Link>
        <Nav.Link className={"sidebar-link"} style={{ color: Color.DarkGrey, paddingRight: "1rem", paddingLeft: "2rem", paddingTop: ".7rem", paddingBottom: ".7rem", width: "100%" }} onClick={() => props.onSelect(Path.Deliveries)}>
            <FaBoxes size={"1.5rem"} style={{ marginRight: "1rem" }} /><span style={{ fontSize: "1rem" }}>Deliveries</span>
        </Nav.Link>
    </BNavbar>
)

export interface NavbarViewProps {
    couriers: CourierAbridged[]
    activeCourier: CourierAbridged
    onChangeCourier: (CourierID: string) => void;
    onAddCourier: () => void;
    onSelect: (path: Path) => void;
    onLogout: () => void;
}

export const DesktopCourierAdminNavbar: FunctionComponent<NavbarViewProps> = (props) => (
    <BNavbar className="elevate" style={{ background: Color.White, padding: 0, width: "100vw", position: "fixed", zIndex: 9 }}>
        <Container style={{ maxWidth: "100%", paddingLeft: "2rem", paddingRight: "2rem" }} fluid>
            <div>
                <img style={{ display: "inline-block", marginRight: ".5rem" }} width={50} height={50} src={`/logos/${process.env.REACT_APP_LOGO}`} />
            </div>
            <Nav style={{ textAlign: "center" }}>

            </Nav>
            <Nav>
                <Dropdown as={NavItem} style={{ color: Color.DarkGrey }}>
                    <Dropdown.Toggle as={NavLink}>
                        <BNavbar.Brand
                            style={{ cursor: "pointer" }}
                        >
                            <MdAccountCircle size={30} style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top", color: Color.Primary }} />
                            <span style={{ color: Color.Primary, fontWeight: "bold", fontSize: "1rem" }}>{props.activeCourier.name}</span>
                        </BNavbar.Brand>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={"end"}>
                        <Dropdown.Item onClick={() => props.onAddCourier()}>
                            <FaPlus style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                            <span>Add new Courier</span>
                        </Dropdown.Item>
                        {props.couriers.map(Courier => (
                            <Dropdown.Item active={props.activeCourier.id === Courier.id} onClick={() => props.onChangeCourier(Courier.id)}>
                                <FaStore style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                                <div style={{ display: "inline-block" }}>
                                    <span style={{ display: "block" }}>{Courier.name}</span>
                                    <span style={{ display: "block", fontSize: ".7rem", width: "100%", wordWrap: "break-word" }}>{Courier.address}</span>
                                </div>
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Item onClick={() => props.onLogout()}>
                            <MdLogout style={{ margin: ".3rem .5rem .3rem 0", verticalAlign: "top" }} />
                            <span>Logout</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav>
        </Container>
    </BNavbar>
);

export const MobileCourierAdminNavbar: FunctionComponent<NavbarViewProps> = (props) => (
    <BNavbar className="elevate" style={{ background: Color.White, paddingLeft: "2rem", paddingRight: "2rem" }} collapseOnSelect expand="lg">
        <BNavbar.Brand onClick={() => props.onSelect(Path.CourierDashboard)}>
            <img style={{ display: "inline-block", marginRight: ".5rem" }} width={50} height={50} src={`/logos/${process.env.REACT_APP_LOGO}`} />
        </BNavbar.Brand>
        <BNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BNavbar.Collapse id="responsive-navbar-nav">
            <Nav style={{ textAlign: "center" }} className="mr-auto">
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Home)}>
                    <FaHome size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Dashboard</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Drivers)}>
                    <FaLocationArrow size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Drivers</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Delivery)}>
                    <FaLocationArrow size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Delivery Settings</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onSelect(Path.Deliveries)}>
                    <FaBoxes size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Deliveries</span>
                </Nav.Link>
                <Nav.Link style={{ "marginLeft": "0.4rem", marginRight: ".4rem", padding: "1rem" }} onClick={() => props.onLogout()}>
                    <MdLogout size={"1.5rem"} style={{ marginRight: "1rem" }} /><span>Logout</span>
                </Nav.Link>
            </Nav>
        </BNavbar.Collapse>
    </BNavbar>
);

export const UnauthenticatedNavbar: FunctionComponent<NavbarViewProps> = (
    props
) => (
    <BNavbar style={{ background: Color.White, position: "fixed", width: "100vw", zIndex: 9 }} variant="dark">
        <Container style={{ maxWidth: "100%", paddingLeft: "2rem", paddingRight: "2rem" }} fluid>
            <BNavbar.Brand
                style={{ cursor: "pointer" }}
                onClick={() => props.onSelect(Path.Home)}
            >
                <img style={{ display: "inline-block", marginRight: ".5rem" }} width={50} height={50} src={`/logos/${process.env.REACT_APP_LOGO}`} />
            </BNavbar.Brand>
            <Nav>
                <Nav.Link onClick={() => props.onSelect(Path.Login)}>
                    Login
                </Nav.Link>
            </Nav>
        </Container>
    </BNavbar>
);
