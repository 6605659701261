import { RouteComponentProps } from "react-router-dom";
import { Driver, IDeleteDriver } from "../../client/core";
import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Path, Storage } from "../../env";
import { Button, variant } from "../form/Button";


export interface DeleteDriverState {
    error: string;
}

export interface DeleteDriverProps extends RouteComponentProps {
    driver: Driver;
    auth: typeof Auth;
    deleteDriverAPI: IDeleteDriver;
    deleteCallback: (driver: Driver) => void
    cancelCallback: () => void
}

export default class DeleteDriver extends Component<DeleteDriverProps, DeleteDriverState> {
    constructor(props: DeleteDriverProps) {
        super(props);
        this.state = {
            error: "",
        };
    }

    componentDidMount() { }

    handleDeleteDriver = (): void => {
        const courierID = localStorage.getItem(Storage.CourierID)
        if (!courierID) {
            return this.signOut()
        }

        this.props.auth.currentSession().then(session => {
            this.props.deleteDriverAPI.DeleteDriver({
                courierID: courierID,
                driverID: this.props.driver.id!,
                identityToken: session.getIdToken().getJwtToken()
            }).then(response => {
                if (response instanceof Error) {
                    return this.setState({
                        error: "This driver could not be deleted at this time. If this issue persists please contact support@stumbled.online"
                    })
                }

                if (response.statusCode === 403) {
                    localStorage.clear()
                    return this.signOut()
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "This driver could not be deleted at this time. If this issue persists please contact support@stumbled.online"
                    })
                }

                this.props.deleteCallback(this.props.driver)
            })
        }).catch(err => {
            return this.signOut()
        })
    }

    signOut = () => {
        localStorage.removeItem(Storage.IsLoggedIn)
        this.props.auth.signOut()
        this.props.history.push(Path.Login)
    }

    render() {
        const deleteDriverViewProps: DeleteDriverViewProps = {
            ...this.props,
            error: this.state.error,
            onSubmit: this.handleDeleteDriver,
            onCancel: this.props.cancelCallback,
        };
        return <DeleteDriverView {...deleteDriverViewProps} />;
    }
}

export interface DeleteDriverViewProps extends RouteComponentProps {
    error: string;
    onCancel: () => void;
    onSubmit: () => void;
}

export const DeleteDriverView: FunctionComponent<DeleteDriverViewProps> = (props) => (
    <div>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <Row>
            <Col style={{ textAlign: "center" }}>
                <p>Are you sure you want to delete this driver?</p>
            </Col>
        </Row>
        <Row>
            <Col style={{textAlign: "right"}}>
                <Button name={"Cancel"} onClick={() => props.onCancel()} variant={variant.Secondary} />
            </Col>
            <Col style={{textAlign: "left"}}>
                <Button name={"Delete"} onClick={() => props.onSubmit()} variant={variant.Primary} />
            </Col>
        </Row>
    </div>
);
