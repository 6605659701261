import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Storage } from "../../env";
import {
    IGetDelivery,
    DeliverySettings as DeliveryDto,
    UpdateDeliveryRadius,
    UpdateDeliveryPrice,
    DeliveryDate as DeliveryDateDto,
    Day,
    UpdateDeliveryDate,
} from "../../client/core";
import DeliveryRadius from "./DeliveryRadius";
import DeliveryPrice from "./DeliveryPrice";
import { Alert, Col, Row } from "react-bootstrap";
import DeliveryDate from "./DeliveryDate";

export interface DeliveryState {
    delivery?: DeliveryDto;
    error: string;
}

export interface DeliveryProps extends RouteComponentProps {
    auth: typeof Auth;
    getDeliveryAPI: IGetDelivery;
}

export default class Delivery extends Component<DeliveryProps, DeliveryState> {
    constructor(props: DeliveryProps) {
        super(props);
        this.state = {
            error: "",
        };
    }

    updateRadius = (value: number): void => {
        const { delivery } = this.state;
        if (!delivery) {
            return;
        }
        delivery!.radius = value;
        this.setState({
            delivery: delivery,
        });
    };

    componentDidMount() {
        this.props.auth
            .currentSession()
            .then((session) => {
                this.props.getDeliveryAPI
                    .GetDelivery({
                        identityToken: session.getIdToken().getJwtToken(),
                        courierID: localStorage.getItem(Storage.CourierID)!,
                    })
                    .then((response) => {
                        if (response.statusCode === 403) {
                            localStorage.removeItem(Storage.CourierID);
                            return this.setState({
                                error: "You are forbidden to access this Couriers delivery information. Please navigate back to the Courier dashboard.",
                            });
                        }

                        if (response.statusCode !== 200) {
                            return this.setState({
                                error: "Unable to retrieve the current delivery radius set",
                            });
                        }

                        this.setState({
                            delivery: response.delivery,
                        });
                    })
                    .catch(() => {
                        return this.setState({
                            error: "Unable to retrieve the current delivery radius set",
                        });
                    });
            })
            .catch(() => {
                return this.setState({
                    error: "Unable to retrieve the current delivery radius set",
                });
            });
    }

    render() {
        const deliveryRadiusViewProps: DeliveryViewProps = {
            delivery: this.state.delivery,
            error: this.state.error,
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
        };
        return <ViewDelivery {...deliveryRadiusViewProps} />;
    }
}

export interface DeliveryViewProps extends RouteComponentProps {
    delivery?: DeliveryDto;
    error: string;
}

function marshalDates(dates: DeliveryDateDto[]): Map<Day, DeliveryDateDto> {
    const result: Map<Day, DeliveryDateDto> = new Map();
    dates.forEach((date) => {
        result.set(date.day, date);
    });
    return result;
}

export const ViewDelivery: FunctionComponent<DeliveryViewProps> = (props) => (
    <div>
        <h1 style={{marginBottom: "2rem"}}>Delivery Settings</h1>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={12} md={6} style={{marginBottom: "2rem"}}>
                <DeliveryRadius
                    radius={
                        props.delivery && props.delivery.radius
                            ? props.delivery.radius
                            : 0
                    }
                    auth={Auth}
                    updateDeliveryRadiusAPI={new UpdateDeliveryRadius()}
                    {...props}
                />
            </Col>
            <Col sm={12} md={6} style={{marginBottom: "2rem"}}>
                <DeliveryPrice
                    price={
                        props.delivery && props.delivery.price
                            ? props.delivery.price
                            : 0
                    }
                    auth={Auth}
                    updateDeliveryPriceAPI={new UpdateDeliveryPrice()}
                    {...props}
                />
            </Col>
        </Row>
        <Row>
            <Col style={{marginBottom: "2rem"}}>
                <DeliveryDate
                    dates={
                        props.delivery && props.delivery.dates
                            ? marshalDates(props.delivery.dates)
                            : new Map()
                    }
                    auth={Auth}
                    updateDeliveryDateAPI={new UpdateDeliveryDate()}
                    {...props}
                />
            </Col>
        </Row>
    </div>
);
