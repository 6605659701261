import { persistStore } from "redux-persist";
import { persistedReducer } from "./reducers"
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
    reducer: persistedReducer,
});

export const persister = persistStore(store)

export type RootState = ReturnType<typeof store.getState>


