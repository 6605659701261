import { Provider } from "react-redux";
import { Routes } from "./routes";
import { Amplify } from "aws-amplify";
import { PersistGate } from "redux-persist/integration/react";
import { persister, store } from "./state/store";
import { Path } from "./env";

require("dotenv").config();

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            redirectSignIn: window.location.protocol + "//" + window.location.host + Path.Login,
            redirectSignOut: window.location.protocol + "//" + window.location.host + Path.Login,
            responseType: 'token',
        }
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_S3_IMAGE_UPLOAD_BUCKET_NAME,
            region: process.env.REACT_APP_S3_REGION,
        }
    },
});

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persister}>
                <div className="App" style={{height: "100vh"}}>
                    <Routes />
                </div>
            </PersistGate>
        </Provider>
    );
}

export default App;