import { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, Col, Row } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../env";
import { IUpdateDeliveryRadius } from "../../client/core";
import { Button, variant } from "../form/Button";

export interface DeliveryRadiusState {
    radius: number;
    action: Action;
    error: string;
}

export enum Action {
    Update,
    View,
}

export interface DeliveryRadiusProps extends RouteComponentProps {
    radius: number;
    auth: typeof Auth;
    updateDeliveryRadiusAPI: IUpdateDeliveryRadius;
}

export default class DeliveryRadius extends Component<
    DeliveryRadiusProps,
    DeliveryRadiusState
> {
    constructor(props: DeliveryRadiusProps) {
        super(props);
        this.state = {
            radius: 0,
            action: Action.View,
            error: "",
        };
    }

    handleChange = (value: number) => {
        this.setState({
            radius: value,
        });
    };

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    handleSubmit = () => {
        this.props.auth
            .currentSession()
            .then((session) => {
                this.props.updateDeliveryRadiusAPI
                    .UpdateDeliveryRadius({
                        identityToken: session.getIdToken().getJwtToken(),
                        radius: this.state.radius,
                        courierID: localStorage.getItem(Storage.CourierID)!,
                    })
                    .then((response) => {
                        if (response.statusCode == 403) {
                            localStorage.removeItem(Storage.CourierID);
                            return this.setState({
                                error: "You were forbidden to update the delivery radius - please navigate back to the Courier dashboard.",
                            });
                        }

                        if (response.statusCode !== 201) {
                            return this.setState({
                                error: "Unable to update delivery radius",
                            });
                        }

                        this.setState({
                            action: Action.View,
                        });
                    })
                    .catch(() => {
                        return this.setState({
                            error: "Unable to update delivery radius",
                        });
                    });
            })
            .catch(() => this.props.auth.signOut());
    };

    changeAction = (action: Action) => {
        this.setState({
            action: action,
        });
    };

    render() {
        const deliveryRadiusViewProps: DeliveryRadiusViewProps = {
            radius:
                this.state.radius == 0 ? this.props.radius : this.state.radius,
            error: this.state.error,
            changeAction: this.changeAction,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
        };
        return (
            <>
                {this.state.action === Action.Update && (
                    <UpdateDeliveryRadiusForm {...deliveryRadiusViewProps} />
                )}
                {this.state.action === Action.View && (
                    <ViewDeliveryRadius {...deliveryRadiusViewProps} />
                )}
            </>
        );
    }
}

export interface DeliveryRadiusViewProps {
    radius: number;
    error: string;
    changeAction: (action: Action) => void;
    onChange?: (value: number) => void;
    onNavigate?: (path: Path) => void;
    onSubmit?: () => void;
}

export const UpdateDeliveryRadiusForm: FunctionComponent<
    DeliveryRadiusViewProps
> = (props) => (
    <Card style={{ height: "100%" }}>
        <Card.Body>
            <Card.Title
                style={{
                    paddingBottom: ".3rem",
                }}
            >
                Delivery Area
            </Card.Title>
            <Card.Subtitle
                style={{
                    paddingBottom: ".5rem",
                }}
            >
                Set a delivery area using a radius to determine how far you will
                travel to deliver orders in <strong>meters</strong>.<br/><br/> Set to 0 if you don't deliver.
            </Card.Subtitle>
            <Form>
                <Alert variant={"danger"} show={props.error != ""}>
                    {props.error}
                </Alert>
                <Row>
                    <Col xs={9}>
                        <Form.Group>
                            <Form.Control
                                pattern="[0-9]*"
                                id={"radius"}
                                type={"number"}
                                name={"radius"}
                                placeholder="Radius"
                                onChange={(e) =>
                                    props.onChange!(Number(e.target.value))
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <span>in meters</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col>
                        <Button
                            id="delivery-radius-cancel-btn"
                            name={"Cancel"}
                            variant={variant.Secondary}
                            onClick={() => props.changeAction(Action.View)}
                        />
                        <Button
                            id="delivery-radius-submit-btn"
                            name={"Submit"}
                            variant={variant.Primary}
                            onClick={() => props.onSubmit!()}
                        />
                    </Col>
                </Row>
            </Form>
        </Card.Body>
    </Card>
);

export const ViewDeliveryRadius: FunctionComponent<DeliveryRadiusViewProps> = (
    props
) => (
    <Card style={{ height: "100%" }}>
        <Card.Body>
            <Row>
                <Col xs={12} sm={10}>
                    <Card.Title
                        style={{
                            paddingBottom: ".3rem",
                        }}
                    >
                        Delivery Area
                    </Card.Title>
                    <Card.Subtitle
                        style={{
                            paddingBottom: ".5rem",
                        }}
                    >
                        Set a delivery area using a radius to determine how
                        far you will travel to deliver orders in{" "}
                        <strong>meters</strong>.
                    </Card.Subtitle>
                    <Card.Text>Current Radius: {props.radius}m</Card.Text>
                </Col>
                <Col xs={12} sm={2}>
                    <Button
                        name={"Edit"}
                        variant={variant.Primary}
                        style={{ float: "right" }}
                        onClick={() => props.changeAction(Action.Update)}
                    />
                </Col>
            </Row>
        </Card.Body>
    </Card>
);
