import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Order, GetDeliveriesOutput, IGetDeliveries, OrderItem, Delivery, IUpdateDeliveryStatus, Pickup, IAssignDriverToDelivery, Driver } from "../../client/core";
import { Color, Path, Storage } from "../../env";
import { Accordion, Alert, Badge, Card, Col, Container, ListGroup, Nav, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { Button, icon, variant } from "../form/Button";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typeahead } from "react-bootstrap-typeahead";


export interface ListDeliveriesState {
    isLoading: boolean;
    activeDay: string;
    days: string[];
    drivers: Driver[];
    driverNameToAssign: string;
    deliveries: Record<string, Record<string, Delivery[]>>
    filteredDeliveries: Record<string, Record<string, Delivery[]>>
    shopPickups: Record<string, Pickup[]>;
    error: string;
}

export interface ListDeliveriesProps extends RouteComponentProps {
    getDeliveriesAPI: IGetDeliveries;
    updateDeliveryStatusAPI: IUpdateDeliveryStatus;
    assignDriverToDeliveryAPI: IAssignDriverToDelivery;
    auth: typeof Auth;
}

export default class ListOrders extends Component<
    ListDeliveriesProps,
    ListDeliveriesState
> {

    constructor(props: ListDeliveriesProps) {
        super(props)
        this.state = {
            isLoading: true,
            activeDay: "",
            days: [],
            drivers: [],
            driverNameToAssign: "",
            deliveries: {},
            filteredDeliveries: {},
            shopPickups: {},
            error: "",
        }
    }

    componentDidMount(): void {
        const courierId = localStorage.getItem(Storage.CourierID)
        if (courierId == null) {
            this.props.auth.signOut()
            this.props.history.push(Path.Login)
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.getDeliveriesAPI.GetDeliveries({
                courierID: courierId,
                identityToken: session.getIdToken().getJwtToken(),
            }).then(async (output: GetDeliveriesOutput | Error) => {

                if (output instanceof Error) {
                    return this.setState({
                        isLoading: false,
                        error: "We could not retrieve the deliveries at this time. If this problem persists please contact: support@stumbled.online"
                    })
                }

                if (output.statusCode === 403) {
                    localStorage.clear()
                    return this.signOut()
                }

                if (output.statusCode !== 200 || !output.response) {
                    return this.setState({
                        isLoading: false,
                        error: "We could not retrieve the deliveries at this time. If this problem persists please contact: support@stumbled.online"
                    })
                }

                let activeDay = ""
                if (output.response.deliveryDays && output.response.deliveryDays.length != 0) {
                    activeDay = output.response.deliveryDays[0]
                }

                console.log(output.response)

                this.setState({
                    isLoading: false,
                    activeDay: activeDay,
                    days: output.response.deliveryDays,
                    deliveries: output.response.deliveries,
                    filteredDeliveries: output.response.deliveries,
                    shopPickups: output.response.shopPickups,
                    drivers: output.response.drivers,
                })
            }).catch(err => {
                return this.setState({
                    error: "We could not retrieve the deliveries at this time. If this problem persists please contact: support@stumbled.online",
                    isLoading: false,
                })
            })
        }).catch(err => {
            return this.signOut()
        })
    }

    handleDeliveryStatusChange = (paymentID: string, status: string): void => {
        const courierId = localStorage.getItem(Storage.CourierID)
        if (courierId == null) {
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.updateDeliveryStatusAPI.UpdateDeliveryStatus({
                courierID: courierId,
                paymentID: paymentID,
                identityToken: session.getIdToken().getJwtToken(),
                status: status,
            }).then(response => {
                if (response instanceof Error || response.statusCode !== 200) {
                    return this.setState({
                        error: "Sorry, we could not update the delivery status at this time. If this error persists please contact support@stumbled.online"
                    })
                }

                const { deliveries } = this.state
                updateDelivery(paymentID, deliveries, delivery => {
                    delivery.status = status
                    return delivery
                })

                this.setState({
                    deliveries: deliveries,
                    filteredDeliveries: deliveries,
                })
            })
        })
    }

    handleDayChange = (day: string): void => {
        this.setState({
            activeDay: day,
        })
    }

    handleAssignDriver = (delivery: Delivery, driverName: string): void => {
        const courierID = localStorage.getItem(Storage.CourierID)
        if (!courierID) {
            return
        }

        const foundDriver = this.state.drivers.find(d => d.name = driverName)
        if (!foundDriver) {
            return this.setState({
                error: `Could not assign driver at this time because driver could not be found with name: ${driverName}`
            })
        }

        this.props.auth.currentSession().then(session => {
            this.props.assignDriverToDeliveryAPI.AssignDriverToDelivery({
                identityToken: session.getIdToken().getJwtToken(),
                courierID: courierID,
                deliveryID: delivery.paymentId,
                driver: foundDriver,
            }).then(response => {
                console.log(response)
                if (response instanceof Error) {
                    return this.setState({
                        error: "Could not assign this driver to the delivery at this time. If this problem persists please contact support@stumbled.online.",
                    })
                }

                if (response.statusCode === 403) {
                    localStorage.clear()
                    return this.signOut()
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Could not assign this driver to the delivery at this time. If this problem persists please contact support@stumbled.online.",
                    })
                }

                const { deliveries } = this.state
                updateDelivery(delivery.paymentId, deliveries, (delivery): Delivery => {
                    delivery.driver = foundDriver
                    return delivery
                })

            })
        }).catch(err => {
            this.signOut()
        })
    }

    signOut = () => {
        localStorage.removeItem(Storage.IsLoggedIn)
        this.props.auth.signOut()
        this.props.history.push(Path.Login)
    }

    handleDriverAssignedChange = (delivery: Delivery, name: string) => {
        const { drivers } = this.state

        const foundDriver = drivers.find(d => d.name === name)
        if (!foundDriver) {
            return
        }

        this.handleAssignDriver(delivery, name)
    }

    handleDeliveryFilter = (comparator: (delivery: Delivery) => boolean) => {
        const { deliveries } = this.state
        const filteredDeliveries: Record<string, Record<string, Delivery[]>> = {}
        Array.from(Object.keys(deliveries)).forEach(day => {
            Array.from(Object.keys(deliveries[day])).forEach(time => {
                deliveries[day][time].forEach((delivery, index) => {
                    if (comparator(delivery)) {
                        if (!filteredDeliveries[day]) {
                            filteredDeliveries[day] = {}
                        }
                        if (!filteredDeliveries[day][time]) {
                            filteredDeliveries[day][time] = []
                        }
                        filteredDeliveries[day][time].push(delivery)
                    }
                })
            })
        })
        this.setState({
            filteredDeliveries: filteredDeliveries,
        })
    }

    render(): ReactNode {
        const props: ListDeliveriesViewProps = {
            error: this.state.error,
            activeDay: this.state.activeDay,
            days: this.state.days,
            deliveries: this.state.deliveries,
            drivers: this.state.drivers,
            shopPickups: this.state.shopPickups,
            onAssignDriverSubmit: this.handleAssignDriver,
            onDeliveryChange: this.handleDeliveryStatusChange,
            onAssignDriverChange: this.handleDriverAssignedChange,
            onDayChange: this.handleDayChange,
        }

        if (this.state.isLoading) {
            return <div style={{ textAlign: "center" }}><Spinner variant="success" color={Color.Primary} animation="border" /></div>
        }

        return <ListDeliveriesView {...props} />
    }
}

const updateDelivery = (paymentId: string, deliveries: Record<string, Record<string, Delivery[]>>, callback: (delivery: Delivery) => Delivery): Record<string, Record<string, Delivery[]>> => {
    Array.from(Object.keys(deliveries)).forEach(day => {
        Array.from(Object.keys(deliveries[day])).forEach(time => {
            deliveries[day][time].forEach((delivery, index) => {
                if (delivery.paymentId == paymentId) {
                    deliveries[day][time][index] = callback(delivery)
                }
            })
        })
    })
    return deliveries
}

export interface ListDeliveriesViewProps {
    error: string
    activeDay: string
    days: string[]
    drivers: Driver[]
    deliveries: Record<string, Record<string, Delivery[]>>
    shopPickups: Record<string, Pickup[]>;
    onAssignDriverSubmit: (delivery: Delivery, driverName: string) => void
    onAssignDriverChange: (delivery: Delivery, driverName: string) => void
    onDeliveryChange: (deliveryID: string, status: string) => void;
    onDayChange: (day: string) => void;
}

export const ListDeliveriesView: FunctionComponent<ListDeliveriesViewProps> = (props) => (
    <div>
        <h1>Deliveries</h1>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={12} md={8} style={{ paddingTop: ".5rem" }}>
                <DaySelector activeDay={props.activeDay} days={props.days} onDayChange={props.onDayChange} />
            </Col>
            <Col sm={12} md={4} style={{ textAlign: "right", paddingTop: ".5rem" }}>
                <Button name="Assigned to me" onClick={() => { }} variant={variant.Primary} />
            </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
            <Accordion>
                {props.shopPickups[props.activeDay] && Array.from(Object.values(props.shopPickups[props.activeDay]).map(shopPickup => (
                    <div style={{ marginTop: "1rem" }}>
                        <Col xs={12} sm={6} xxl={3} style={{ textAlign: "center" }}>
                            <Accordion.Item style={{ width: "100%", display: "inline-block" }} eventKey={shopPickup.shop.address}>
                                <Accordion.Header>
                                    <Row style={{ width: "100%", marginRight: ".2rem", marginLeft: ".2rem" }}>
                                        <Col>
                                            <span>{shopPickup.shop.name}</span>
                                        </Col>
                                        <Col style={{ textAlign: "right" }}>
                                            {(shopPickup.packages) && <span>Packages: <Badge bg="secondary">{shopPickup.packages.length}</Badge></span>}
                                        </Col>
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table style={{ textAlign: "left" }}>
                                        <tbody>
                                            <tr>
                                                <th>Customer Address</th>
                                                <th>Items</th>
                                            </tr>
                                            {shopPickup.packages && shopPickup.packages.map(shopPackage => (
                                                <tr>
                                                    <td>{shopPackage.address}</td>
                                                    <td>{shopPackage.itemTotal}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Col>
                    </div>
                )))}
            </Accordion>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
            <Col>
                <div style={{ borderTop: `.5px solid ${Color.Grey}` }}>
                    {(props.deliveries[props.activeDay] && Array.from(Object.keys(props.deliveries[props.activeDay])).length > 0) ? Array.from(Object.keys(props.deliveries[props.activeDay])).map(time => (
                        <div style={{ marginTop: "1rem" }}>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <span style={{ fontWeight: 600 }}>{time}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row style={{ borderRight: "1px dashed lightgrey" }}>
                                        <DeliveriesView
                                            title="To Be Delivered"
                                            status={"ToBeDelivered"}
                                            deliveries={props.deliveries[props.activeDay]![time]!.filter(delivery => delivery.status != "Delivered") || []}
                                            drivers={props.drivers}
                                            onClick={delivery => props.onDeliveryChange(delivery.paymentId, "Delivered")}
                                            onAssignDriverSubmit={props.onAssignDriverSubmit}
                                            onAssignDriverChange={props.onAssignDriverChange}
                                        />
                                    </Row>
                                </Col>
                                <Col>
                                    <Row style={{ borderLeft: "1px dashed lightgrey" }}>
                                        <DeliveriesView
                                            title="Delivered"
                                            status={"Delivered"}
                                            drivers={props.drivers}
                                            deliveries={props.deliveries[props.activeDay]![time]!.filter(delivery => delivery.status == "Delivered") || []}
                                            onClick={delivery => props.onDeliveryChange(delivery.paymentId, "ToBeDelivered")}
                                            onAssignDriverSubmit={props.onAssignDriverSubmit}
                                            onAssignDriverChange={props.onAssignDriverChange}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )) : (
                        <div style={{ marginTop: "1rem" }}>
                            <Row>
                                <Col style={{ padding: "1rem" }}>
                                    <FontAwesomeIcon color={Color.Primary} style={{ display: "inline-block", fontSize: "1.5rem", marginRight: "1rem" }} icon={faCircleCheck} />
                                    <Card.Text style={{ display: "inline-block" }}>You currently have no deliveries pending</Card.Text>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    </div>
)

export interface DeliveriesViewProps {
    title: string
    status: string
    deliveries: Delivery[]
    drivers: Driver[]
    onAssignDriverChange: (delivery: Delivery, name: string) => void
    onAssignDriverSubmit: (delivery: Delivery, driverName: string) => void
    onClick: (delivery: Delivery) => void
}

export const DeliveriesView: FunctionComponent<DeliveriesViewProps> = props => (
    <div style={{ padding: "1rem", display: "block" }}>
        <h6>{props.title}</h6>
        <Accordion>
            {props.deliveries && props.deliveries.map((delivery: Delivery) => (
                <Row style={{ marginBottom: ".5rem" }}>
                    {props.status == "Delivered" && (
                        <Col xs={3} md={2}>
                            <Button
                                style={{ width: "100%", height: "100%" }}
                                icon={icon.LeftArrow}
                                variant={variant.Danger}
                                onClick={() => props.onClick(delivery)} />
                        </Col>
                    )}
                    <Col xs={9} md={10}>
                        <Accordion.Item style={{ width: "100%", display: "inline-block" }} eventKey={delivery.address}>
                            <Accordion.Header>
                                <Container>
                                    <Row style={{ width: "100%", marginRight: ".2rem", marginLeft: ".2rem" }}>
                                        <Col>
                                            <p>Delivery: {delivery.address}</p>
                                        </Col>
                                        <Col style={{ textAlign: "right" }}>
                                            <span style={{ fontWeight: 700 }}>Total: £{Number(delivery.price).toFixed(2)}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%", marginRight: ".2rem", marginLeft: ".2rem" }}>
                                        <Col>
                                            {(delivery.driver?.name) ?
                                                (
                                                    <>
                                                        <span style={{ display: "inline-block", marginRight: ".3rem" }}>Driver: </span>
                                                        <Typeahead
                                                            labelKey="driver"
                                                            className="no-border"
                                                            style={{ flex: "1 1 auto", display: "inline-block" }}
                                                            id={"driver"}
                                                            defaultInputValue={delivery.driver.name}
                                                            onInputChange={value => {
                                                                props.onAssignDriverChange(delivery, value)
                                                            }}
                                                            onChange={value => {
                                                                props.onAssignDriverSubmit(delivery, value[0] as string)
                                                            }}
                                                            options={props.drivers.map(driver => driver.name)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span style={{ display: "inline-block", marginRight: ".3rem" }}>Driver:</span>
                                                        <Typeahead
                                                            style={{ flex: "1 1 auto", display: "inline-block" }}
                                                            id={"driver"}
                                                            placeholder="Unassigned"
                                                            onInputChange={value => {
                                                                props.onAssignDriverChange(delivery, value)
                                                            }}
                                                            onChange={value => {
                                                                props.onAssignDriverSubmit(delivery, value[0] as string)
                                                            }}
                                                            options={props.drivers.map(driver => driver.name)}
                                                        />
                                                    </>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>Customer Email</td>
                                            <td>{delivery.customerEmail}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Address</td>
                                            <td>{delivery.address}</td>
                                        </tr>
                                        <tr>
                                            <td>Details</td>
                                            {(!delivery.details) && (<td>None</td>)}
                                            {(delivery.details) && (<td>{delivery.details}</td>)}
                                        </tr>
                                        <tr>
                                            <td>Orders</td>
                                            <td>
                                                <ListGroup style={{ width: "100%" }}>
                                                    {delivery.orders &&
                                                        Array.from(Object.keys(delivery.orders)).map((key) => {
                                                            const order: Order = delivery.orders[key]!
                                                            return (
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <div className="ms-2">
                                                                        <div className="fw-bold">{order.shop.name}</div>
                                                                        <div>{order.shop.address}</div>
                                                                    </div>
                                                                    <Badge bg="secondary" style={{ color: Color.White, padding: ".5rem", margin: ".1rem", float: "right" }}>Items: {order.itemTotal}</Badge>
                                                                </ListGroup.Item>
                                                            )
                                                        })}
                                                </ListGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Col>
                    {props.status == "ToBeDelivered" && (
                        <Col xs={4} md={2}>
                            <Button
                                style={{ width: "100%", height: "100%" }}
                                icon={icon.RightArrow}
                                variant={variant.Primary}
                                onClick={() => props.onClick(delivery)} />
                        </Col>
                    )}
                </Row>
            ))}
        </Accordion >
    </div >
)

export interface ListItemsViewProps {
    items: OrderItem[];
}

export const ListItemsView: FunctionComponent<ListItemsViewProps> = (props) => (
    <ListGroup style={{ width: "100%" }}>
        {props.items &&
            props.items.map((item, key) => (
                <ListGroup.Item
                    as="li"
                    style={{ width: "100%" }}
                >
                    <div className="ms-2">
                        <div className="fw-bold">{item.name}</div>
                        <div className="fw-bold" style={{ float: "right" }}>£{Number(item.price).toFixed(2)}</div>
                    </div>
                    {item.variants && item.variants.map(variant => (
                        <Badge bg={"secondary"} style={{ color: Color.White, padding: ".5rem", margin: ".1rem" }}>{variant.name}: {variant.value}</Badge>
                    ))}
                    <Badge bg={"secondary"} style={{ color: Color.White, padding: ".5rem", margin: ".1rem" }}>{item.volume + " " + item.uom} {((item.volume && item.volume > 1) && (!item.uom || item.uom == "Unit")) ? "s" : ""}</Badge>
                </ListGroup.Item>
            ))}
    </ListGroup>
);

export interface DaySelectorProps {
    activeDay: string
    days: string[]
    onDayChange: (day: string) => void;
}

export const DaySelector: FunctionComponent<DaySelectorProps> = props => (
    <Nav className="me-auto">
        {props.days.map(day => (props.activeDay === day) ? (
            <Nav.Link style={{ background: Color.Primary, padding: 10, color: Color.White, fontWeight: "bold" }} onClick={() => props.onDayChange(day)}>{day}</Nav.Link>
        ) : (

            <Nav.Link style={{ color: Color.Primary, fontWeight: 600 }} onClick={() => props.onDayChange(day)}>{day}</Nav.Link>
        ))}
    </Nav>
)