import { combineReducers } from "redux";
import { CouriersReducer } from "./couriers";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

export const rootReducer = combineReducers({
    Couriers: CouriersReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootReducer = ReturnType<typeof persistedReducer>;