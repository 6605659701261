import { CourierAbridged } from "../../client/core";

export interface CouriersState {
    couriers: CourierAbridged[];
}

export const UPDATE_COURIERS = "UPDATE_COURIERS";

export interface CouriersAction {
    type: string;
    payload: CourierAbridged[];
}
