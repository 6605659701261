import { RouteComponentProps } from "react-router-dom";
import { Driver, IAddDriver } from "../../client/core";
import { Auth, Storage as S3 } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Path, Storage } from "../../env";
import { Button, variant } from "../form/Button";


export interface AddDriverState {
    error: string;
    driverImage?: File;
    driver: Driver;
}

export interface AddDriverProps extends RouteComponentProps {
    auth: typeof Auth;
    imageStore: typeof S3;
    addDriverAPI: IAddDriver;
    addDriverCallback: (driver: Driver) => void
}

export default class AddDriver extends Component<AddDriverProps, AddDriverState> {
    constructor(props: AddDriverProps) {
        super(props);
        this.state = {
            error: "",
            driver: {
                email: "",
                name: "",
            }
        };
    }

    componentDidMount() { }

    handleAddDriver = (): void => {
        const courierID = localStorage.getItem(Storage.CourierID)
        if (!courierID) {
            return this.signOut()
        }

        this.props.auth.currentSession().then(session => {
            this.props.addDriverAPI.AddDriver({
                courierID: courierID,
                driver: {
                    name: this.state.driver.name,
                    email: this.state.driver.email,
                },
                identityToken: session.getIdToken().getJwtToken(),
            }).then(async response => {
                if (response instanceof Error) {
                    return this.setState({
                        error: "Sorry, we were unable to add a driver at this time. If this issue persists please contact support@stumbled.online"
                    })
                }

                if (response.statusCode === 403) {
                    localStorage.clear();
                    return this.signOut()
                }

                if (response.statusCode !== 201) {
                    return this.setState({
                        error: "Sorry, we were unable to add a driver at this time. If this issue persists please contact support@stumbled.online"
                    })
                }

                this.props.addDriverCallback({
                    ...this.state.driver,
                    id: response.body?.driver.id,
                })

                const imageName = `${courierID}/drivers/${response.body?.driver.id}`

                await this.props.imageStore.put(
                    imageName,
                    this.state.driverImage,
                    {
                        contentType: "image/jpeg",
                    }
                );

            })
        }).catch(err => {
            return this.signOut()
        })
    }

    handleImageChange = (image: File) => {
        this.setState({
            driverImage: image,
        })
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e!.target!.result;
            this.setState({
                driver: {
                    ...this.state.driver,
                    image: text?.toString() || "",
                }
            })
        }
        reader.readAsDataURL(image)
    }

    handleNameChange = (name: string) => {
        this.setState({
            driver: {
                ...this.state.driver,
                name: name,
            }
        })
    }

    handleEmailChange = (email: string) => {
        this.setState({
            driver: {
                ...this.state.driver,
                email: email,
            }
        })
    }

    signOut = () => {
        localStorage.removeItem(Storage.IsLoggedIn)
        this.props.auth.signOut()
        this.props.history.push(Path.Login)
    }

    render() {
        const deliveryViewProps: AddDriverViewProps = {
            ...this.props,
            error: this.state.error,
            onAddDriver: this.handleAddDriver,
            onImageChange: this.handleImageChange,
            onEmailChange: this.handleEmailChange,
            onNameChange: this.handleNameChange,
        };
        return <AddDriverView {...deliveryViewProps} />;
    }
}

export interface AddDriverViewProps extends RouteComponentProps {
    error: string;
    onImageChange: (image: File) => void;
    onNameChange: (name: string) => void;
    onEmailChange: (email: string) => void;
    onAddDriver: () => void
}

export const AddDriverView: FunctionComponent<AddDriverViewProps> = (props) => (
    <div>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <Form>
            <Row>
                <Form.Label>Image</Form.Label>
                <Col>
                    <div className="input-group mb-3">
                        <div className="custom-file" style={{ width: "100%" }}>
                            <input
                                type="file"
                                className="form-control"
                                id="inputGroupFile"
                                accept="image/jpeg"
                                style={{
                                    display: "block",
                                    width: "100%"
                                }}
                                onChange={(e) => {
                                    props.onImageChange(e.target.files![0]);
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" onChange={e => props.onNameChange(e.currentTarget.value)} placeholder="John Smith" />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" onChange={e => props.onEmailChange(e.currentTarget.value)} placeholder="name@example.com" />
            </Form.Group>
            <Row>
                <Col style={{ textAlign: "right" }}>
                    <Button name={"Submit"} onClick={() => props.onAddDriver()} variant={variant.Primary} />
                </Col>
            </Row>
        </Form>
    </div>
);
