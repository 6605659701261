import { Auth } from "aws-amplify";
import React, { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { IRegisterCourier } from "../../../client/core";
import { Color, Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";

export interface RegisterCourierState {
    error: string;
}

export interface RegisterCourierProps extends RouteComponentProps {
    coreAPI: IRegisterCourier;
    auth: typeof Auth;
}

export default class RegisterCourier extends Component<
    RegisterCourierProps,
    RegisterCourierState
> {
    constructor(props: RegisterCourierProps) {
        super(props);
        this.state = {
            error: "",
        };
    }

    handleSubmit = () => {
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.coreAPI
                    .RegisterCourier({
                        identityToken: token,
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            return this.props.history.push(Path.Login);
                        }

                        if (output.statusCode === 201) {
                            localStorage.setItem(Storage.CourierID, output.courierID);
                            return this.props.history.push(Path.RegisterCourierName);
                        }

                        this.setState({
                            error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                        });
                    })
                    .catch(() =>
                        this.setState({
                            error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                        })
                    );
            })
            .catch(() => {
                this.props.history.push(Path.Login);
            });
    };

    render() {
        const props: RegisterCourierFormProps = {
            error: this.state.error,
            onSubmit: this.handleSubmit,
        };
        return <RegisterCourierForm {...props} />;
    }
}

export interface RegisterCourierFormProps {
    error: string;
    onSubmit: () => void;
}

export const RegisterCourierForm: FunctionComponent<RegisterCourierFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Card>
            <Card.Body>
                <Card.Title>Register your Courier</Card.Title>
                <Card.Text>If you are not registering to sell produce, and instead want to buy produce, you can download our mobile app from the App Store: <span style={{ color: Color.Primary, fontWeight: "bold" }}>Stumbled: Local Shops</span> or visit <a style={{color: Color.Primary}} href={"https://shops.stumbled.online"}>https://shops.stumbled.online</a>. You will be able to login with the same credentials.</Card.Text>
                <Card.Text>If you wish to register your Courier please click on the button below and continue with your journey.</Card.Text>
                <Button
                    variant={variant.Primary}
                    name={"Register your Courier"}
                    onClick={() => props.onSubmit()} />
            </Card.Body>
        </Card>
    </Form>
);
